<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column; justify-content: center; margin-top: -40px;">
    <div
      style="max-width: 500px; width: 90%;" 
      height="" 
      class="mx-auto"
    >
      <v-stepper v-if="inbound" v-model="two" class="mb-2" max-width="500" style="align-self: center;">
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="true"
          >
            {{$t('signin')}}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
            :complete="false"
          >
            {{$t('loginflow.create_team')}}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="3"
            :complete="false"
          >
            {{$t('loginflow.checkout')}}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
export default {
  computed: {
    inbound() {
      return !!this.$route.query?.plan
    },
    two() {
      return 2
    }
  }
}
</script>